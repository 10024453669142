import React, { Component } from 'react'; //different
//import './style.css';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
import { json } from 'react-router-dom';
import { LoopTwoTone } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mainsetting: [],
            rewardlist: [],
            activePage: 1,
            pagecount: 1,
            products_page_count: 1,
            isActive: false,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            stateopterror: "",
            stateregis: 1,
            showpass: true,
            bankselect: 0,
            trueaccount: true,
            username: null,
            fname: "",
            checkboxacc: 0,
            userid: null,
            phoneNumber: "",
            userrecomend: "",
            linelink: "",
            balance: 0,
            mainacc: null,
        }
        this.forgetotpandregister = this.forgetotpandregister.bind(this);
    }
    notify = (mode, msg) => {
        if (mode == "success") {
            toast.success(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "warning") {
            toast.warning(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "error") {
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "info") {
            toast.info(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }
    confirmdeposit = async (e, num) => {

        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;

        await instance.post("/api/v1/getredeempromotion", {
            userid: Username,
            System: Systems,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                this.setState({
                    proderedeem: res.data.message.tblpromotionredeems,
                });

            }
        }).catch(async (err) => {
        });

        if (num === 0) {
            if ((this.state.depositamount >= this.state.limit.min) && (this.state.depositamount <= this.state.limit.max)) {
                const { depositstate } = this.state
                ////////console.log(depositstate, num);
                if (num === 0) {
                    if (token != null) {
                        instance.post("/api/v1/getqrcode", {
                            username: Username,
                            System: Systems,
                            amount: this.state.depositamount
                        }, {
                            headers: { Authorization: `Bearer ${token}` }
                        }).then(async (res) => {
                            ////////console.log(res.data.picture)
                            this.setState({
                                mainacc: res.data.mainacc,
                                qrcode: res.data.tblpaymentgatewayx.QRcode,
                                paymentlist: res.data.tblpaymentgatewayx,
                            });
                        });
                    }

                    this.setState({
                        depositstate: 1
                    });
                }
            }
            else {
                await Swal.fire({
                    title: 'Warning!',
                    text: 'เติมขั้นต่ำ ' + this.state.limit.min + ' บาท สูงสุด ' + this.state.limit.max + ' ',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
            }
        }
        else if (num === 1) {
            this.setState({
                depositstate: 1
            });
        }
        else {
            this.setState({
                depositstate: 0
            });
        }



    }
    radiocheck(input) {
        console.log(input);
        this.setState({
            bankselect: input
        });
        if (input == 99) {
            this.setState({
                trueaccount: false
            });
        }
        else {
            this.setState({
                trueaccount: true,
                fname: ""
            });
        }
    }
    forgetotpandregister = async (type) => {
        const { phoneNumber, otpphoneforget, bankselect, otpphone, accnumbertxt } = this.state;
        var recheck = false;
        if (type === 4) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;
            });
        }
        else if (type === 5) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                pin: otpphoneforget,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;

            });
        }
        else if (type === 6) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;
            });
        }
        else if (type === 7) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                pin: otpphone,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;

            });
        }
        else if (type === 10) {
            return await instance.post("/api/v1/resetpassword", {
                accountnumber: accnumbertxt,
                bankid: bankselect,
                System: Systems,
                type: type
            }).then(async (res) => {
                return recheck = res.data.message;

            });
        }
        return recheck
    }
    nextstep = async (e, num) => {
        e.preventDefault();
        const { stateregis, password, bankselect, passwordcf, phoneNumber, checkboxacc, fname, otpphone, accnumbertxt } = this.state
        console.log(num);
        if (num === 1) {
            const check = await this.forgetotpandregister(6);
            if (phoneNumber.length === 10 && check.checked) {
                this.setState({
                    stateregis: 2
                });
            }
            else {
                if (phoneNumber.length !== 10) {
                    await this.notify("warning", 'คุณใส่เบอร์ผิดพลาด');
                }
                else if (!check.checked && check.status === 201) {
                    await this.notify("warning", 'เบอร์นี้มีคนใช้แล้ว');
                }
                else {
                    await this.notify("warning", 'คุณทำรายการถี่เกินไป โปรดรอซักครู่');
                    this.setState({
                        stateopterror: "คุณทำรายการถี่เกินไป โปรดรอซักครู่"
                    });
                }
            }
        }
        else if (num === 2) {

            if (password !== passwordcf) {
                await this.notify("warning", 'คุณไส่พาสเวิดไม่ตรงกัน');
            }
            else if (password === '') {
                await this.notify("warning", 'คุณไม่ใส่พาสเวิด');
            }
            else if (password.length < 6) {
                await this.notify("warning", 'ใส่พาสเวิดน้อยกว่า 6 ตัว ');
            }
            else {
                this.setState({
                    stateregis: 3
                });
            }

        }
        else if (num === 3) {
            if (checkboxacc !== 0 && fname !== "" && accnumbertxt !== "") {
                this.setState({
                    stateregis: 3
                });
            }
            else {
                await this.notify("warning", 'กรุณาเลือกธนาคาร หรือใส่ข้อมูลให้ครบถ้วน');
                //////console.log(2, stateregis, checkboxacc);
            }
        }
        else if (num === 5) {
            var check = await this.forgetotpandregister(7);
            if (otpphone !== "" && check.checked) {
                this.setState({
                    stateregis: 2
                });
            }
            else if (otpphone === "" || otpphone.length < 4) {
                await this.notify("warning", 'คุณใส่ตัวเลขไม่ครบ');
                document.getElementById("otpphone").style = "border-color: #ef4444;";
                document.getElementById("checkotp").style.display = "block";
            }

            else {
                await this.notify("warning", 'รหัสผิดพลาด');
                document.getElementById("otpphone").style = "border-color: #ef4444;";
                document.getElementById("checkotp").style.display = "block";
            }



        }
        else if (num === 11) {
            console.log(1, stateregis, num);
            this.setState({
                stateregis: 1
            });
        }
        else if (num === 22) {
            //////console.log(1, stateregis, num);
            this.setState({
                stateregis: 2
            });
        }
        else if (num === 25) {
            console.log("checkboxacc",checkboxacc)
            if (accnumbertxt === "" || typeof accnumbertxt == "undefined") {
                var title = 'error'
                var text = 'คุณไม่ทำการใส่เลขบัญชีธนาคาร'
                var object = { title, text }
                this.modalerror(object);

            }
            else if (accnumbertxt.length < 10) {
                var title = 'error'
                var text = 'โปรดตรวจสอบเลขบัญชีอีกครั้ง'
                var object = { title, text }
                this.modalerror(object);
            }
            else if (checkboxacc === 0) {
                var title = 'error'
                var text = 'คุณยังไม่ได้เลือกธนาคาร'
                var object = { title, text }
                this.modalerror(object);
            }
            else {
                this.setState({
                    loading: true,
                });
                if (fname === '') {
                    let check = await this.forgetotpandregister(10)
                    if (check.checked) {
                        console.log(302, check.verifications);
                        console.log(302, check.verifications.name);
                        if (check.verifications.status == 0) {
                            this.setState({
                                trueaccount: false,
                            });
                        }
                        else if (check.verifications.status == 1) {
                            this.setState({
                                fname: check.verifications.name,
                            });
                            await this.validatergisterForm(e, check.verifications.name);
                        }
                    }
                    else {
                        var title = 'error'
                        var text = 'เลขบัญชีไม่ถูกต้อง'
                        var object = { title, text }
                        this.modalerror(object);
                    }
                }
                else {
                    await this.validatergisterForm(e, fname)
                }
                this.setState({
                    loading: false,
                });
                //console.log(302, check.verifications);
            }


        }
    };

    onChangex = event => {
        // console.log("event", event.target.name);


        if (event.target !== undefined) {
            let nam;
            let val;
            if (event.target.name === "otpphone") {
                document.getElementById("otpphone").style = "";
                document.getElementById("checkotp").style.display = "none";
            }
            else if (event.target.name === "phoneNumber") {
                document.getElementById("phoneNumberforget").style = "";
                document.getElementById("checkotpforgetphone").style.display = "none";
            }
            else if (event.target.name === "otpphoneforget") {
                document.getElementById("otpphoneforget").style = "";
                document.getElementById("checkotpforget").style.display = "none";
            }
            else if (event.target.name === "otpphoneforget") {
                document.getElementById("phoneNumber").style = "";
                document.getElementById("checkotpforgetphonenormal").style.display = "none";
            }

            if (event.target.name === undefined) {
                nam = event.name;
                val = event.value;
            } else {
                nam = event.target.name;
                val = event.target.value;
            }
            this.setState({ [nam]: val });
        }
    };
    async modalerror(object) {
        // await Swal.fire({
        //     title: object.title + "!",
        //     text: object.text,
        //     icon: object.title,
        //     confirmButtonText: 'ตกลง'
        // })
        await this.notify(object.title, object.text);
    }
    validatergisterForm = async (e, fname) => {
        e.preventDefault();
        const { phoneNumber, password, passwordcf, checkboxacc, accnumbertxt } = this.state;
        console.log(checkboxacc);
        console.log(this.state)
        if (accnumbertxt === "" || typeof accnumbertxt == "undefined") {
            var title = 'error'
            var text = 'คุณไม่ทำการใส่เลขบัญชีธนาคาร'
            var object = { title, text }
            this.modalerror(object);

        }
        else if (fname === "") {

            var title = 'error'
            var text = 'คุณไม่ทำการใส่ ชื่อ-นามสกุล'
            var object = { title, text }
            this.modalerror(object);

        }
        else if (password.length > 15) {
            var title = 'error'
            var text = 'พาสเวิดสูงสุดไม่เกิน 15 ตัว'
            var object = { title, text }
            this.modalerror(object);
        }
        else if (password.length < 6) {
            var title = 'error'
            var text = 'พาสเวิดต่ำไม่เกิน 15 ตัว'
            var object = { title, text }
            this.modalerror(object);
        }
        else if (password != passwordcf) {
            var title = 'error'
            var text = 'รหัสยิืนยันไม่ตรงกัน'
            var object = { title, text }
            this.modalerror(object);
        }
        else {
            await instance.post("/api/v1/register", {
                User_id: phoneNumber,
                name: fname,
                password: password,
                accbank: checkboxacc,
                accno: accnumbertxt,
                System: Systems,
                Useradvise: this.state.userrecomend
            }).then(async (res) => {
                console.log(res.data);
                if (res.data.status === 200) {
                    var title = 'success'
                    var text = 'ทำการสมัครเรียบร้อย กรุณารอซักครู่'
                    var object = { title, text }
                    await this.modalerror(object);


                    await instance.post("/api/v1/userlogin", {
                        userid: phoneNumber,
                        password: password,
                        System: Systems,
                        Function: 'validateLoginForm-navbar.js'
                    }).then((res) => {
                        if (res.status === 200) {
                            if (res.data.status === 200) {
                                localStorage.setItem('auth_token', res.data.token);
                                setInterval(() =>
                                    window.location.href = '/'
                                    , 4000);

                            }
                        }
                    });
                }
                else if (res.data.status === 203) {
                    var title = 'error'
                    var text = 'เลขบัญชี นี้ทำการสมัครไปแล้ว'
                    var object = { title, text }
                    this.modalerror(object);
                }
                else if (res.data.status === 202) {
                    var title = 'error'
                    var text = 'Username นี้ทำการสมัครไปแล้ว'
                    var object = { title, text }
                    this.modalerror(object);
                }
                else {
                    var title = 'error'
                    var text = 'Username หรือ ชื่อในการสมัครไม่ถูกต้อง'
                    var object = { title, text }
                    this.modalerror(object);
                }
            });
        }


    };
    changepass = (e) => {
        e.preventDefault();
        this.setState({
            showpass: this.state.showpass ? false : true,
        });
    }
    async saveclickaff(linkid) {
        await instance.post("/api/v1/saveclickaff", {
            linkid: linkid,
            System: Systems,
        }).then((res) => {
            if (res.data.status == 200) {
                var date = new Date()
                this.setState({
                    userrecomend: res.data.username,
                    link: linkid
                });
                var username = res.data.username
                var object = { date, username }
                localStorage.setItem('aff', JSON.stringify(object))
            }
        }).catch(async (err) => {
        });

    }
    async saveclick(linkid) {
        await instance.post("/api/v1/saveclickaff", {
            linkid: linkid,
            System: Systems,
        }).then((res) => {
            if (res.data.status == 200) {
                var date = new Date()
                var username = res.data.username
                this.setState({
                    userrecomend: username,
                });
                var object = { date, username }
                localStorage.setItem('aff', JSON.stringify(object))
            }
        }).catch(async (err) => {
        });

    }
    async componentDidMount() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            localStorage.removeItem('auth_token', '')
        }

        const search = window.location.href;
        var test = search.split("aff=")[1]
        if (test != undefined) {
            this.saveclickaff(test.split("&")[0]);
        }
        var params = search.split("reg=")[1]
        if (params != undefined) {
            this.saveclick(params);
            this.setState({
                userrecomend: params.split("&")[0],
            });
        }

        var tokenf = localStorage.getItem('aff');
        if (tokenf != null) {
            var codev = JSON.parse(tokenf)
            //   console.log(codev);
            var Username = codev.username;
            this.setState({
                userrecomend: Username,
            });
        }

        var tokenx = localStorage.getItem('register');
        if (tokenx != null) {
            var codev = JSON.parse(tokenx)
            // console.log(codev);
            var Username = codev.username;
            this.setState({
                userrecomend: Username,
            });
        }


        await instance.post("/api/v1/mainsetting", {
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                const datas = res.data.message;
                var min = res.data.tblcatagoryx[0].limitdeposit;
                var max = res.data.tblcatagoryx[0].maxdeposit;
                var limit = { min, max }
                this.setState({
                    linelink: datas[1].value,
                    System: res.data.tblcatagoryx[0].System,
                    limit: limit
                });
            }
            else {
                // this.CheckThisGoalError();
            }
        });
        //  this.nameInput.focus();
    }





    render() {

        const { stateopterror, userrecomend } = this.state

        //console.log(prodetail.length)
        return (
            <div className="-full-flex-wrap ">
                <div
                    className="-full-flex-inner-bg -bg"

                >
                    <div className="x-promotion-index">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="x-page-title-component ">
                                        <div className="-inner-wrapper">
                                            <h1 className="-title">สมัครสมาชิก</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div
                                        // data-animatable="fadeInRegister"
                                        className="col order-lg-2 order-0 -form  fadeInRegisterform"
                                    >
                                        <div className="x-modal-separator-container ">
                                            <div className="-top " style={{ display: this.state.stateregis === 1 ? "" : "none" }}>
                                                <h3 className="x-title-modal mx-auto text-center ">
                                                    กรอกเบอร์โทรศัพท์
                                                </h3>
                                                <div className="-fake-inner-body">
                                                    <div className="x-step-register -v2">
                                                        <div className="px-0 m-auto -container-wrapper">
                                                            <div className={"-step-box-outer" + (this.state.stateregis !== 1 ? "" : " step-active")}></div>
                                                            <div className={"-step-box-outer" + (this.state.stateregis !== 2 ? "" : " step-active")}></div>
                                                            <div className={"-step-box-outer" + (this.state.stateregis !== 2 ? "" : " step-active")}></div>
                                                            <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                                        </div>
                                                    </div>
                                                    <div >
                                                        <div className="-x-input-icon mb-3 text-center">
                                                            <img
                                                                src="/build/web/igame-index-lobby-wm/img/ic-input-phone.png"
                                                                className="-icon"
                                                                alt="SA Casino phone icon"
                                                                width={10}
                                                                height={14}
                                                            />
                                                            <input
                                                                onChange={this.onChangex}
                                                                type="text"
                                                                id="phoneNumber"
                                                                name="phoneNumber"
                                                                required="required"
                                                                pattern="[0-9]*"
                                                                className="x-form-control form-control"
                                                                placeholder="กรุณากรอกเบอร์โทรศัพท์"
                                                                autoFocus="autoFocus"
                                                                autoComplete="on"
                                                                inputMode="text"
                                                            />
                                                            <div id="checkotpforgetphonenormal" className="invalid-feedback ">
                                                                <ul className="list-unstyled mb-0"><li>{stateopterror}</li></ul>
                                                            </div>
                                                        </div>
                                                        <h3 className="x-title-modal mx-auto text-center " style={{ marginBottom: "15px" }}>
                                                            ผู้แนะนำ
                                                        </h3>
                                                        <div className="-x-input-icon mb-3 text-center">
                                                            <img
                                                                src="/build/web/igame-index-lobby-wm/img/ic-user-v2.png"
                                                                className="-icon"
                                                                alt="SA Casino phone icon"
                                                                width={10}
                                                                height={14}
                                                            />
                                                            <input
                                                                onChange={this.onChangex}
                                                                readOnly={true}
                                                                style={{ color: "black", background: "transparent" }}
                                                                type="text"
                                                                id="userrecomend"
                                                                name="userrecomend"
                                                                required="required"
                                                                value={userrecomend}
                                                                className="x-form-control form-control"
                                                                placeholder="ผู้แนะนำ"
                                                                autoFocus="autoFocus"
                                                                autoComplete="on"
                                                                inputMode="text"
                                                            />
                                                        </div>
                                                        <div className="text-center">
                                                            <button
                                                                ref={(input) => { this.nameInput = input; }}
                                                                onClick={(e) => {
                                                                    this.nextstep(e, 1)
                                                                }}
                                                                type="submitx"
                                                                className="btn   btn-primary mt-lg-3 mt-0"
                                                                disabled=""
                                                            >
                                                                ต่อไป
                                                            </button>
                                                        </div>
                                                        <input
                                                            type="hidden"
                                                            id="request_otp__token"
                                                            name="request_otp[_token]"
                                                            defaultValue="ds26nEjb1GacmW8racC7ScvGiCgTEnPl5xKWBzZyMWc"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="-top " style={{ display: this.state.stateregis === 5 ? "" : "none" }}>
                                                <h3 className="x-title-modal mx-auto text-center ">
                                                    ยืนยัน OTP
                                                </h3>
                                                <div className="-img-container ">
                                                    <picture>
                                                        <source
                                                            type="image/webp"
                                                            srcSet="https://sacasino.co/build/web/ezc-sa-casino/img/register-icon-otp-v2.webp"
                                                        />
                                                        <source
                                                            type="image/png?v=5"
                                                            srcSet="https://sacasino.co/build/web/ezc-sa-casino/img/register-icon-otp-v2.webp"
                                                        />
                                                        <img
                                                            alt="กรอกรหัส OTP เพื่อยืนยันตัวตน"
                                                            className="img-fluid -ic-otp"
                                                            width={150}
                                                            height={150}
                                                            src="https://sacasino.co/build/web/ezc-sa-casino/img/register-icon-otp-v2.webp"
                                                        />
                                                    </picture>
                                                </div>

                                                <div className="-fake-inner-body">
                                                    <div className="x-step-register -v2">
                                                        <div className="px-0 m-auto -container-wrapper">
                                                            <div className={"-step-box-outer" + (this.state.stateregis !== 5 ? "" : " step-active")}></div>
                                                            <div className={"-step-box-outer" + (this.state.stateregis !== 5 ? "" : " step-active")}></div>
                                                            <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                                            <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                                        </div>
                                                    </div>
                                                    <div >
                                                        <div className="-x-input-icon mb-3 text-center">
                                                            <img
                                                                src="/build/web/igame-index-lobby-wm/img/ic-input-phone.png"
                                                                className="-icon"
                                                                alt="SA Casino phone icon"
                                                                width={10}
                                                                height={14}
                                                            />
                                                            <input
                                                                onChange={this.onChangex}
                                                                type="text"
                                                                id="otpphone"
                                                                name="otpphone"
                                                                required="required"
                                                                pattern="[0-9]*"
                                                                maxLength="4"
                                                                className="x-form-control form-control"
                                                                placeholder="กรุณากรอก OTP 4 หลัก"
                                                                autoFocus="autoFocus"
                                                                autoComplete="on"
                                                                inputMode="text"
                                                            />
                                                            <div id="checkotp" className="invalid-feedback ">
                                                                <ul className="list-unstyled mb-0"><li>{stateopterror}</li></ul>
                                                            </div>
                                                        </div>
                                                        <div className="text-center">
                                                            <a href="#0" style={{ cursor: "pointer" }}
                                                                onClick={(e) => {
                                                                    this.nextstep(e, 11)
                                                                }} className="text-muted mt-3">
                                                                ยกเลิก
                                                            </a>
                                                        </div>
                                                        <div className="text-center">

                                                            <button
                                                                onClick={(e) => {
                                                                    this.nextstep(e, 5)
                                                                }}
                                                                type="submitx"
                                                                className="btn   btn-primary mt-lg-3 mt-0"
                                                                disabled=""
                                                            >
                                                                ต่อไป
                                                            </button>
                                                        </div>
                                                        <input
                                                            type="hidden"
                                                            id="request_otp__token"
                                                            name="request_otp[_token]"
                                                            defaultValue="ds26nEjb1GacmW8racC7ScvGiCgTEnPl5xKWBzZyMWc"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="-top " style={{ display: this.state.stateregis === 3 ? "" : "none" }}>
                                                <h3 className="x-title-modal mx-auto text-center ">
                                                    กรอกเลขที่บัญชี
                                                </h3>
                                                <div className="-x-input-icon mb-3 text-center">
                                                    <div
                                                        data-animatable="fadeInModal"
                                                        className="-animatable-container animated fadeInModal"
                                                    >
                                                        <div className="text-center -img-container">
                                                            <div className="-title mt-3">กรุณาเลือกบัญชีธนาคารของท่าน</div>
                                                        </div>
                                                        <div className="x-step-register -v2">
                                                            <div className="px-0 m-auto -container-wrapper">
                                                                <div className={"-step-box-outer" + (this.state.stateregis !== 2 ? "" : " step-active")}></div>
                                                                <div className={"-step-box-outer" + (this.state.stateregis !== 2 ? "" : " step-active")}></div>
                                                                <div className={"-step-box-outer" + (this.state.stateregis !== 2 ? "" : " step-active")}></div>
                                                                <div className={"-step-box-outer" + (this.state.stateregis !== 1 ? "" : " step-active")}></div>
                                                            </div>
                                                        </div>
                                                        <div id="card-bank-info">
                                                            <div className="text-center">
                                                                <div className="my-3 js-bank-select-container">
                                                                    <div className="x-bank-choices-type ">
                                                                        <div className="x-bank-choices-type ">
                                                                            <div className="-outer-wrapper" id='checkboxacc' onChange={this.onChangex}>
                                                                                <input
                                                                                    type="radio"
                                                                                    className="-input-radio"
                                                                                    id="bank-acc-51692356943"
                                                                                    name="checkboxacc"
                                                                                    defaultValue={5}
                                                                                />
                                                                                <label className="-label" htmlFor="bank-acc-51692356943">
                                                                                    <img
                                                                                        className="-logo"
                                                                                        src="/media/cache/strip/201910/84c0f97b9a4a02bd519f6256da291d06.png"
                                                                                        alt=""
                                                                                    />
                                                                                    <i className="fas fa-check" />
                                                                                </label>
                                                                                <input
                                                                                    type="radio"
                                                                                    className="-input-radio"
                                                                                    id="bank-acc-61692356943"
                                                                                    name="checkboxacc"
                                                                                    defaultValue={6}
                                                                                    data-gtm-form-interact-field-id={6}
                                                                                />
                                                                                <label className="-label" htmlFor="bank-acc-61692356943">
                                                                                    <img
                                                                                        className="-logo"
                                                                                        src="/media/cache/strip/201910/75cf7484ae422a0d3897e82556cb04fd.png"
                                                                                        alt=""
                                                                                    />
                                                                                    <i className="fas fa-check" />
                                                                                </label>
                                                                                <input
                                                                                    type="radio"
                                                                                    className="-input-radio"
                                                                                    id="bank-acc-71692356943"
                                                                                    name="checkboxacc"
                                                                                    defaultValue={7}
                                                                                />
                                                                                <label className="-label" htmlFor="bank-acc-71692356943">
                                                                                    <img
                                                                                        className="-logo"
                                                                                        src="/media/cache/strip/201910/ffcda61156271aa5d354b4d052590a14.png"
                                                                                        alt=""
                                                                                    />
                                                                                    <i className="fas fa-check" />
                                                                                </label>
                                                                                <input
                                                                                    type="radio"
                                                                                    className="-input-radio"
                                                                                    id="bank-acc-81692356943"
                                                                                    name="checkboxacc"
                                                                                    defaultValue={8}
                                                                                />
                                                                                <label className="-label" htmlFor="bank-acc-81692356943">
                                                                                    <img
                                                                                        className="-logo"
                                                                                        src="/media/cache/strip/201910/4982427c6801f5e172ab1205a9166e2f.png"
                                                                                        alt=""
                                                                                    />
                                                                                    <i className="fas fa-check" />
                                                                                </label>
                                                                                <input
                                                                                    type="radio"
                                                                                    className="-input-radio"
                                                                                    id="bank-acc-91692356943"
                                                                                    name="checkboxacc"
                                                                                    defaultValue={9}
                                                                                />
                                                                                <label className="-label" htmlFor="bank-acc-91692356943">
                                                                                    <img
                                                                                        className="-logo"
                                                                                        src="/media/cache/strip/201910/62cdaab59a028355f43825a426c41b94.png"
                                                                                        alt=""
                                                                                    />
                                                                                    <i className="fas fa-check" />
                                                                                </label>
                                                                                <input
                                                                                    type="radio"
                                                                                    className="-input-radio"
                                                                                    id="bank-acc-111692356943"
                                                                                    name="checkboxacc"
                                                                                    defaultValue={11}
                                                                                />
                                                                                <label className="-label" htmlFor="bank-acc-111692356943">
                                                                                    <img
                                                                                        className="-logo"
                                                                                        src="/media/cache/strip/201910/6220cfdee8ca83b5533e6d5ac87b778b.png"
                                                                                        alt=""
                                                                                    />
                                                                                    <i className="fas fa-check" />
                                                                                </label>
                                                                                <input
                                                                                    type="radio"
                                                                                    className="-input-radio"
                                                                                    id="bank-acc-121692356943"
                                                                                    name="checkboxacc"
                                                                                    defaultValue={12}
                                                                                />
                                                                                <label className="-label" htmlFor="bank-acc-121692356943">
                                                                                    <img
                                                                                        className="-logo"
                                                                                        src="/media/cache/strip/201910/a3671c1989ca4935f046b184b98b28d1.png"
                                                                                        alt=""
                                                                                    />
                                                                                    <i className="fas fa-check" />
                                                                                </label>
                                                                                <input
                                                                                    type="radio"
                                                                                    className="-input-radio"
                                                                                    id="bank-acc-141692356943"
                                                                                    name="checkboxacc"
                                                                                    defaultValue={14}
                                                                                />
                                                                                <label className="-label" htmlFor="bank-acc-141692356943">
                                                                                    <img
                                                                                        className="-logo"
                                                                                        src="/media/cache/strip/201910/de6513eee5e2d90848de36a1be081ef5.png"
                                                                                        alt=""
                                                                                    />
                                                                                    <i className="fas fa-check" />
                                                                                </label>
                                                                                <input
                                                                                    type="radio"
                                                                                    className="-input-radio"
                                                                                    id="bank-acc-151692356943"
                                                                                    name="checkboxacc"
                                                                                    defaultValue={15}
                                                                                />
                                                                                <label className="-label" htmlFor="bank-acc-151692356943">
                                                                                    <img
                                                                                        className="-logo"
                                                                                        src="/media/cache/strip/201910/4320a4686226cba7c35cfdbbe25a0e95.png"
                                                                                        alt=""
                                                                                    />
                                                                                    <i className="fas fa-check" />
                                                                                </label>
                                                                                <input
                                                                                    type="radio"
                                                                                    className="-input-radio"
                                                                                    id="bank-acc-161692356943"
                                                                                    name="checkboxacc"
                                                                                    defaultValue={16}
                                                                                />
                                                                                <label className="-label" htmlFor="bank-acc-161692356943">
                                                                                    <img
                                                                                        className="-logo"
                                                                                        src="/media/cache/strip/201910/48e5e7cc029492341f34b7ce38926edd.png"
                                                                                        alt=""
                                                                                    />
                                                                                    <i className="fas fa-check" />
                                                                                </label>
                                                                                <input
                                                                                    type="radio"
                                                                                    className="-input-radio"
                                                                                    id="bank-acc-171692356943"
                                                                                    name="checkboxacc"
                                                                                    defaultValue={17}
                                                                                />
                                                                                <label className="-label" htmlFor="bank-acc-171692356943">
                                                                                    <img
                                                                                        className="-logo"
                                                                                        src="/media/cache/strip/201910/a68c3e5add8754e2c0e986c408c08c24.png"
                                                                                        alt=""
                                                                                    />
                                                                                    <i className="fas fa-check" />
                                                                                </label>
                                                                                <input
                                                                                    type="radio"
                                                                                    className="-input-radio"
                                                                                    id="bank-acc-991692356943"
                                                                                    name="checkboxacc"
                                                                                    defaultValue={99}
                                                                                />
                                                                                <label className="-label" htmlFor="bank-acc-991692356943">
                                                                                    <img
                                                                                        className="-logo"
                                                                                        src="https://yt3.googleusercontent.com/aWp38-LVqL_fztxB3VkBaYHj6otE3dmCq-QMhEJUdRvDQbn1xU5gvU9CrB15x6-aWbLA9oQ1CQ=s900-c-k-c0x00ffffff-no-rj"
                                                                                        alt=""
                                                                                    />
                                                                                    <i className="fas fa-check" />
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="js-bank-number-and-name-container position-relative">
                                                                    <div className="x-bank-info-validate-box -v2">
                                                                        <div className="-content-validate">
                                                                            โปรดตรวจสอบ ชื่อ และ เลขบัญชี !!
                                                                        </div>
                                                                        <div className="-detail-validate">
                                                                            หากไม่ถูกต้อง จะไม่สามารถแก้ไข หรือ ฝากถอนได้
                                                                        </div>
                                                                        <i className="fas fa-arrow-right" />
                                                                    </div>
                                                                    <div className="-x-input-icon mb-3 flex-column">
                                                                        <img
                                                                            className="-icon"
                                                                            alt="กระเป๋าเงิน ฝากถอน ออโต้"
                                                                            width={15}
                                                                            height={15}
                                                                            src="/build/web/igame-index-lobby-wm/img/ic-library-book-v2.png?v=5"
                                                                        />
                                                                        <input
                                                                            type="text"
                                                                            onChange={this.onChangex}
                                                                            id="accnumbertxt"
                                                                            name="accnumbertxt"
                                                                            required="required"
                                                                            pattern="[0-9]*"
                                                                            className="x-form-control form-control"
                                                                            placeholder="กรอกเลขบัญชี"
                                                                            autoFocus="autoFocus"
                                                                            inputMode="text"
                                                                        />
                                                                    </div>
                                                                    <div className="-x-input-icon mb-3 flex-column" style={{ display: this.state.trueaccount ? "none" : "" }}>
                                                                        <img
                                                                            className="-icon"
                                                                            alt="รองรับการเข้าใช้งานหลายผู้เล่น 24 ชั่วโมง"
                                                                            width={15}
                                                                            height={15}
                                                                            src="/build/web/igame-index-lobby-wm/img/ic-user-v2.png?v=5"
                                                                        />
                                                                        <input
                                                                            type="text"
                                                                            onChange={this.onChangex}
                                                                            value={this.state.fname}
                                                                            id="fname"
                                                                            name="fname"
                                                                            required="required"
                                                                            className="x-form-control form-control"
                                                                            placeholder="กรอก ชื่อ - นามสกุล (ภาษาไทยเท่านั้น)"
                                                                        />
                                                                    </div>
                                                                    <div className="-text-hint col-sm-10 m-auto d-block d-lg-none">
                                                                        กรอกข้อมูลให้ครบถ้วนและถูกต้องมิฉะนั้นท่านจะไม่สามารถถอนเงินได้
                                                                    </div>
                                                                </div>
                                                                <a
                                                                    href="#0"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={(e) => {
                                                                        this.nextstep(e, 11)
                                                                    }} className="text-muted mt-3">
                                                                    ยกเลิก
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="hidden"
                                                        id="customer_bank_account__token"
                                                        name="customer_bank_account[_token]"
                                                        defaultValue="EtuWE7npKiT8yTfHa4RNu7uFMEY1cTFVfBKaSda8kGc"
                                                    />

                                                </div>
                                                <div className="text-center">
                                                    <button
                                                        onClick={(e) => {
                                                            this.nextstep(e, 25)
                                                        }}
                                                        type="submitxa"
                                                        className="btn   btn-primary mt-lg-3 mt-0"
                                                        disabled=""
                                                    >
                                                        <div style={{ display: this.state.loading ? "" : "none" }}>
                                                            <span className="spinner-border" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </span>
                                                        </div>
                                                        <div style={{ display: this.state.loading ? "none" : "" }}>
                                                            ต่อไป
                                                        </div>

                                                    </button>
                                                </div>
                                                <input
                                                    type="hidden"
                                                    id="request_otp__token"
                                                    name="request_otp[_token]"
                                                    defaultValue="ds26nEjb1GacmW8racC7ScvGiCgTEnPl5xKWBzZyMWc"
                                                />
                                            </div>
                                            <div className="-top " style={{ display: this.state.stateregis === 2 ? "" : "none" }}>
                                                <h3 className="x-title-modal mx-auto text-center ">
                                                    กรุณา Confirm Password
                                                </h3>
                                                <div className="-fake-inner-body">
                                                    <div className="x-step-register -v2">
                                                        <div className="px-0 m-auto -container-wrapper">
                                                            <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                                            <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                                            <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                                            <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                                        </div>
                                                    </div>
                                                    <div >
                                                        <div className="text-center">

                                                            <div className="-x-input-icon mb-3 text-center">
                                                                <img
                                                                    src="/build/web/igame-index-lobby-wm/img/ic-input-lock.png"
                                                                    className="-icon"
                                                                    alt="SA Casino phone icon"
                                                                    width={10}
                                                                    height={14}
                                                                />
                                                                <input
                                                                    onChange={this.onChangex}
                                                                    type="password"
                                                                    id="password"
                                                                    name="password"
                                                                    required="required"
                                                                    className="x-form-control form-control"
                                                                    placeholder="กรุณารหัสผ่าน"
                                                                    autoFocus="autoFocus"
                                                                    autoComplete="on"
                                                                    inputMode="text"
                                                                />
                                                            </div>
                                                            <div className="-x-input-icon mb-3 text-center">
                                                                <img
                                                                    src="/build/web/igame-index-lobby-wm/img/ic-input-lock.png"
                                                                    className="-icon"
                                                                    alt="SA Casino phone icon"
                                                                    width={10}
                                                                    height={14}
                                                                />
                                                                <input
                                                                    onChange={this.onChangex}
                                                                    type="password"
                                                                    id="passwordcf"
                                                                    name="passwordcf"
                                                                    required="required"
                                                                    className="x-form-control form-control"
                                                                    placeholder="กรุณารหัสผ่าน"
                                                                    autoFocus="autoFocus"
                                                                    autoComplete="on"
                                                                    inputMode="text"
                                                                />
                                                                <div className="mt-3 -spacer  text-center" style={{ display: (this.state.errorstatus ? "" : "none") }}>
                                                                    <div className="x-danger-zone -v2  text-center">
                                                                        <span>{this.state.errortext}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <a href="#0" style={{ cursor: "pointer" }}
                                                                onClick={(e) => {
                                                                    this.nextstep(e, 11)
                                                                }} className="text-muted mt-3">
                                                                ยกเลิก
                                                            </a>
                                                            <button
                                                                onClick={(e) => {
                                                                    this.nextstep(e, 2)
                                                                }}
                                                                type="submitx"
                                                                className="btn btn-primary mt-lg-3 mt-0 -submit"
                                                                disabled=""
                                                            >
                                                                ยืนยัน
                                                            </button>
                                                        </div>
                                                        <input
                                                            type="hidden"
                                                            id="request_otp__token"
                                                            name="request_otp[_token]"
                                                            defaultValue="ds26nEjb1GacmW8racC7ScvGiCgTEnPl5xKWBzZyMWc"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="-bottom ">
                                                <div className="x-admin-contact text-center ">
                                                    <span className="x-text-with-link-component">
                                                        <label className="-text-message ">พบปัญหา</label>
                                                        <a
                                                            href={this.state.linelink}
                                                            className="-link-message "
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <u>ติดต่อฝ่ายบริการลูกค้า</u>
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        );
    }
}
export default App;
