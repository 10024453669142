
import './App.css';
import React, { Component } from 'react';

function App() {
    return (
      <div className="-pre-footer-seo-container js-replace-pre-footer-seo-container">
      <div className="x-index-pre-footer-wrapper">
        <section className="-section-wrapper-1">
          <div className="container">
            <div className="row -row-wrapper">
              <div className="col-12 col-xl-7 -left-container">
                <img
                  src="/build/web/igame-index-lobby-ae-sexy/img/pre-footer-poker-chip-1.png"
                  className="-poker-chip"
                  alt="Pg711  Casino Gaming คาสิโนออนไลน์ บาคาร่าออนไลน์"
                />
                <div className="-box-wrapper">
                  <h2 className="-title">PG711</h2>
                  <p className="-description">
                    ให้บริการความสนุกไม่รู้จบ 24 ชม. ไปกับดีลเลอร์ “DEALER” สาวสวย
                    สุดเซ็กซี่ คอยแจกไพ่ ให้คุณได้ลุ้นทุกเกมส์เดิมพัน สบายตา สบายใจ
                    ด้วย สาวๆ น่ารัก สุดเซ็กซี่ มาใส่บิกินี่ แต่ละสีแต่ละวันสีสด
                    มาแจกไพ่ ท่านจะได้รับประสบการณ์ใหม่ในการเดิมพัน
                  </p>
                </div>
              </div>
              <div className="col-12 col-xl-5 -right-container">
                <img
                  src="/build/web/igame-index-lobby-ae-sexy/img/pre-footer-banner-1-1.png"
                  className="-banner-img"
                  alt="Pg711  Casino รูปภาพแบนเนอร์"
                  width={700}
                  height={583}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="-section-wrapper-2">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="-heading-wrapper ">
                  <img
                    src="/build/web/igame-index-lobby-ae-sexy/img/pre-footer-line-glow-red-1.png"
                    className="-red-line"
                    alt="Pg711  Casino รูปภาพเส้นสีเรืองแสง"
                    width={480}
                    height={104}
                  />
                  <h2 className="-title">PG711 คาสิโน สำหรับทุกคน</h2>
                  <p className="-sub-title">PLATFORM ADVANTAGE</p>
                </div>
                <ul className="navbar-nav">
                  <li className="nav-item -hilo">
                    <img
                      src="/build/web/igame-index-lobby-ae-sexy/img/pre-footer-hilo-1.png"
                      className="-item-icon-img"
                      alt="Pg711  Casino รูปภาพเกมส์ ประเภทต่างๆ"
                      width={190}
                      height={190}
                    />
                    <h3 className="-item-title">
                      MoMO ไฮโล Hi-Low
                      <br className="d-none d-sm-block" /> วางสูงต่ำที่ Pg711 
                      casino
                    </h3>
                    <p className="-item-paragraph">
                      ซึ่งนอกจากบาคาร่าออนไลน์แล้วนั้นเว็บไซต์ AE casino
                      ของเรายังมีเกมการพนันอย่าง ไฮโลออนไลน์
                      ที่เป็นเกมการพนันพื้นบ้านที่ได้รับความนิยมจนต้องนำมาเพิ่มในคาสิโนออนไลน์
                      ซึ่ง ไฮโลออนไลน์ ของเราเล่นง่าย ได้เงินจริง
                      คุณอาจจะรวยภายในข้ามคืนหากเดิมพันกับเว็บของเรา
                    </p>
                  </li>
                  <li className="nav-item -rouelete">
                    <img
                      src="/build/web/igame-index-lobby-ae-sexy/img/pre-footer-rouelete-1.png"
                      className="-item-icon-img"
                      alt="Pg711  Casino รูปภาพเกมส์ ประเภทต่างๆ"
                      width={190}
                      height={190}
                    />
                    <h3 className="-item-title">
                      รูเล็ต เล่นน้อยได้มาก
                      <br className="d-none d-sm-block" /> ให้สาวๆ Pg711 พาคุณรวย
                    </h3>
                    <p className="-item-paragraph">
                      เกมวงล้อนำโชคอย่าง รูเล็ตต์
                      ในเว็บไซต์ของเราได้นำสาวสวยมาหมุนวงล้อนำโชค
                      ที่จะพาคุณรวยไปกับเกมสนุกสุดมันส์ ซึ่ง รูเล็ตต์ ในเว็บไซต์ AE
                      Sexy คุณสามารถลงทุนน้อยแต่ได้กำไรมาก
                      ให้เราเป็นส่วนนึงในความรวยของคุณ เพียงสมัครสมาชิกกับ AE casino
                    </p>
                  </li>
                  <li className="nav-item -baccarat">
                    <img
                      src="/build/web/igame-index-lobby-ae-sexy/img/pre-footer-baccarat-1.png"
                      className="-item-icon-img"
                      alt="Pg711  Casino รูปภาพเกมส์ ประเภทต่างๆ"
                      width={190}
                      height={190}
                    />
                    <h3 className="-item-title">
                      เออี เซ็กซี่ บาคาร่าออนไลน์
                      <br className="d-none d-sm-block" /> เล่นเว็บตรง Pg711 
                      ที่นี่
                    </h3>
                    <p className="-item-paragraph">
                      สามารถเล่นเว็บตรงไม่ผ่านเอเย่นต์ได้ที่นี่ Pg711 
                      ด้วยความใส่ใจของเราจึงสามารถให้ลูกค้าเล่น บาคาร่าออนไลน์
                      และเกมการพนันอื่นๆ ได้
                      ที่นี่เป็นเว็บตรงไม่ต้องผ่านเอเย่นต์ทำให้สามารถเชื่อใจว่าไม่มีการโกงอย่างแน่นอน
                    </p>
                  </li>
                  <li className="nav-item -dragontiger">
                    <img
                      src="/build/web/igame-index-lobby-ae-sexy/img/pre-footer-dragontiger-1.png"
                      className="-item-icon-img"
                      alt="Pg711  Casino รูปภาพเกมส์ ประเภทต่างๆ"
                      width={190}
                      height={190}
                    />
                    <h3 className="-item-title">
                      AE เสือมังกร ลุ้นไว สนุก
                      <br className="d-none d-sm-block" /> นิยมมากในPg711casino
                    </h3>
                    <p className="-item-paragraph">
                      ส่วนใครที่ชอบเกมเดิมพันไวๆ เรามีเกม เสือมังกร
                      ที่สามารถรวยได้ง่ายๆ ในไม่กี่วินาที
                      เป็นเกมคาสิโนออนไลน์อีกหนึ่งอย่างที่ได้รับความนิยมอย่างมากในเว็บ
                     Pg711casino ของเรา หากใครที่ชอบความสนุกตื่นเต้น
                      ที่มาพร้อมกับความรวยแนะนำให้ลองเล่นPg711casino
                      กับเรารับรองติดใจแน่นอน
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="-section-wrapper-3 ">
          <div
            className="-overlay"
            style={{
              backgroundImage:
                "url(/build/web/igame-index-lobby-ae-sexy/img/pre-footer-section-3-bg.png)"
            }}
          />
          <div className="container">
            <div className="row">
              <div className="col-12">
                <img
                  src="/build/web/igame-index-lobby-ae-sexy/img/pre-footer-banner-2-1.png"
                  className="-banner-img img-fluid"
                  alt="Pg711  Casino รูปแบนเนอร์เว็บไซต์คาสิโน"
                  width={900}
                  height={450}
                />
              </div>
              <div className="col-12">
                <p className="-description">
                  เว็บอันดับหนึ่งPg711ที่รวมสาวสวยมากมายให้ท่านได้ตื่นตาตื่นใจ
                  บาคาร่าออนไลน์ที่ เย้ายวนใจที่สุดในตอนนี้ หรือ ที่รู้จักกันว่า
                  Sexy Baccarat, Sexy Gaming, เออี เซ็กซี่ หรือ เซ็กซี่เกมส์ เออี
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    );
}

export default App;
